import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "published": true
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Notice = makeShortcode("Notice");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Depending on your backend environment, you can use Skyflow’s `}<a parentName="p" {...{
        "href": "/sdks/"
      }}>{`server-side SDKs`}</a>{` to authenticate and generate a bearer token. If your backend is written in a language that we don’t currently support through our SDKs, use the following steps to generate an API bearer token.`}</p>
    <h2 {...{
      "id": "step-1-create-a-service-account--assign-a-role",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#step-1-create-a-service-account--assign-a-role",
        "aria-label": "step 1 create a service account  assign a role permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 1: Create a service account & assign a role`}</h2>
    <p>{`A service account is an identity for machine access to your vault. This allows other application's backends to access your vault. The level of access the service account will have to the data will be determined by the role it is assigned to and the policies attached to that role. `}</p>
    <Notice mdxType="Notice">
    You must be the Vault Owner of a vault to create a service account.  
    </Notice>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Click the `}<strong parentName="p">{`Settings`}</strong>{` tab in the top menu on the Vault Dashboard and select the vault you'll create a service account for from the dropdown menu on the left.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Under the IAM section, click `}<strong parentName="p">{`Service Accounts`}</strong>{` > `}<strong parentName="p">{`New Service Account`}</strong>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Enter a name and description, select Vault Owner as the role, and click `}<strong parentName="p">{`Create`}</strong>{`. You can also assign other roles as needed in this step. `}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Securely store the `}<em parentName="p">{`credentials.json`}</em>{` file that downloads onto your local machine. This file contains a private key that will be used to sign your JWT bearer token. Here is an example credentials.json file:`}</p>
        <pre parentName="li"><code parentName="pre" {...{}}>{`{
    "clientID":"y4b0fb0991b211eb9a5e9a757ffcc4b0",
    "clientName":"Customer support agent web app",
    "tokenURI":"https://manage.skyflowapis.com/v1/auth/sa/oauth/token",
    "keyID":"y4c9577c91b211eb9a5e9a757ffcc4b0",
    "privateKey":"-----BEGIN PRIVATE KEY-----\\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQCihOlTU61VztBu\\nDhQtEb.....oGBAMFEvPAM0arx3qn7C067\\nMLVrW2H6PwGpiWNU86rHBMkuzriwzagtit130XN8KrHfYfSRUmOOw6h6T4aC85g0\\nwC8SQXRntfoise0UWcSxfnyhfqlaUeN3BqhHl0zjRQjE8W9th9k16N0rTPBRmPGo\\nrWELwcVHR6izoGgGBAdWAGVn\\n-----END PRIVATE KEY-----\\n"
}
`}</code></pre>
        <Notice mdxType="Notice">
    The Workspace URL and the Vault ID mentioned in the Service Accounts page will be required as URL paths when integrating with vault APIs.  
        </Notice>
      </li>
    </ol>
    <h2 {...{
      "id": "step-2-prepare-your-environment",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#step-2-prepare-your-environment",
        "aria-label": "step 2 prepare your environment permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 2: Prepare your environment`}</h2>
    <p>{`You'll now run a Python script that takes the credentials.json file from the previous step and uses the private key inside of it to sign a JWT token. This JWT token is then used to make an authentication request to the Skyflow authorization server and subsequently return a bearer token.`}</p>
    <Notice mdxType="Notice">
    This step assumes you have [Homebrew](https://brew.sh/) installed. Additionally, this Python script is for the convenience of completing this quickstart. In a real integration, you'd likely use our SDKs to generate the token.
    </Notice>
    <p>{`To run this script, ensure that you have a compatible environment:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Install Python version 3.5 or above (`}<em parentName="p">{`if you don't already have it`}</em>{`) by running the following command in your terminal:`}</p>
        <pre parentName="li"><code parentName="pre" {...{}}>{`brew install python
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Install the following libraries by running these commands in your terminal:`}</p>
        <pre parentName="li"><code parentName="pre" {...{}}>{`pip3 install PyJWT

pip3 install requests

pip3 install cryptography
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Copy and paste `}<a parentName="p" {...{
            "href": "https://github.com/skyflowapi/Dev-Microsite-Snippets/blob/main/getBearerToken.py"
          }}>{`this Python script`}</a>{` to a code editor. `}</p>
        <Notice mdxType="Notice">
    You can request short-lived access tokens that are valid for no more than 60 minutes by adjusting the expiration field.
        </Notice>
      </li>
      <li parentName="ol">
        <p parentName="li">{`On line 45 of the code, enter the full path to the credentials.json file on your local machine:`}</p>
        <pre parentName="li"><code parentName="pre" {...{}}>{`jwtToken, creds = getSignedJWT('/Users/aj/Downloads/credentials.json')
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Save this python file as "getBearerToken.py". `}</p>
      </li>
    </ol>
    <h2 {...{
      "id": "step-3-run-the-script--generate-an-access-token",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#step-3-run-the-script--generate-an-access-token",
        "aria-label": "step 3 run the script  generate an access token permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 3: Run the script & generate an access token`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Run the following command in your terminal: `}</p>
        <pre parentName="li"><code parentName="pre" {...{}}>{`python3 getBearerToken.py     
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`If you have performed all previous steps correctly, you should see the bearer token displayed in the terminal as follows:`}</p>
        <pre parentName="li"><code parentName="pre" {...{}}>{`{
    "accessToken": "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJodHRwczovL21hbmFnZS5za3lmbG93YXBpcy5kZXYiLCJjbGkiOiJ5NGIwZm....pOqmlI_CWY2V6MEBTqnVHuAo1-9MBSW8REp-mv_-mJqOe8TMb9dOImcXzM7jEpW79Fqs3-HCo-cUikWwy6tjjvVqHW-4pqG005pGzxrAt275Q2LU1pXwUfUM6idH9o2ydlpTp0-ujPQgVQXh8w9LsqE58Qtm4lRU8Sr8FMdx72qnuahD5Xoh1KL7D-DFZaYMrof9aTfUFUctUBzOUbL4_z2bEf2wkHouSPOZGI3uHIM54mjX013NkNXzMltP8GiP5GimC3PX-jA",
    "tokenType": "Bearer"
}
`}</code></pre>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      